import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	heading?: string;
	textAlignment?: 'left' | 'right';
	bottomLead?: boolean;
	headingWidth?: 'short' | 'wide';
};

export const textHeadingPortableTextOverrides: PortableTextOverrides = {};

export const TextHeading: FC<Props> = ({
	heading,
	children,
	textAlignment = 'left',
	bottomLead = false,
	headingWidth = 'short',
}) => {
	return (
		<Container className={clsx('my-14', 'md:my-16')}>
			<div
				className={clsx(
					'flex',
					bottomLead ? 'flex-col' : 'flex-wrap',
					'justify-between',
					'gap-8',
				)}
			>
				<Heading
					className={clsx(
						'text-3xl',
						'md:text-6xl',
						children
							? [
									headingWidth === 'short'
										? 'md:max-w-[483px]'
										: 'max-w-full lg:max-w-[max(75%,1000px)]',
									'shrink-0',
								]
							: ['md:max-w-[1000px]'],
					)}
				>
					{heading}
				</Heading>
				{children && (
					<div
						className={clsx(
							'md:max-w-[910px]',
							'pt-1',
							'md:pt-6',
							textAlignment === 'right'
								? 'text-right *:justify-self-end'
								: 'text-left',
						)}
					>
						{children}
					</div>
				)}
			</div>
		</Container>
	);
};
