'use client';

import type {ReactNode} from 'react';
import {useEffect} from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {VideoPlayer} from '@/modules/foundation/components/video';

import {useHero} from '../../context/hero-context';

type Props = {
	heading: string;
	lead?: ReactNode;
	videoTitle: string;
	videoId: string;
	videoIdMobile?: string;
	textAlignment?: 'left' | 'right';
	hiddenHeading?: boolean;
	bottomLead?: boolean;
};

export const HeaderVideo = ({
	heading,
	videoId,
	videoIdMobile,
	videoTitle,
	lead,
	textAlignment,
	hiddenHeading,
	bottomLead,
}: Props) => {
	const {setHasVideoHero} = useHero();

	useEffect(() => {
		setHasVideoHero(true);

		// Reset the context when the component unmounts
		return () => setHasVideoHero(false);
	}, [setHasVideoHero]);

	return (
		<>
			{videoIdMobile && (
				<div className={clsx('bg-primary', 'h-screen', 'relative', '-mt-24', 'lg:hidden')}>
					<div
						className={clsx(
							'absolute',
							'top-0',
							'left-0',
							'right-0',
							'h-2/5',
							'bg-linear-to-b',
							'from-[#000000]/70',
							'z-10',
						)}
					/>
					<VideoPlayer
						video={{
							id: videoIdMobile,
							title: videoTitle,
						}}
						autoplay
						cover
					/>
				</div>
			)}
			<div
				className={clsx(
					'bg-primary',
					'h-dvh',
					'relative',
					'-mt-24',
					videoIdMobile && ['hidden', 'lg:block'],
				)}
			>
				<div
					className={clsx(
						'absolute',
						'top-0',
						'left-0',
						'right-0',
						'h-2/5',
						'bg-linear-to-b',
						'from-[#000000]/70',
						'z-10',
					)}
				/>
				<VideoPlayer
					video={{
						id: videoId,
						title: videoTitle,
					}}
					autoplay
					cover
				/>
			</div>
			{hiddenHeading ? (
				<h1 className="sr-only">{heading}</h1>
			) : (
				<Container className={clsx('mb-14', 'md:mb-16', 'mt-28', 'md:mt-32')}>
					<div
						className={clsx(
							'flex',
							bottomLead ? 'flex-col' : 'flex-wrap',
							'justify-between',
							'gap-8',
						)}
					>
						<Heading
							level={1}
							className={clsx(
								'text-3xl',
								'md:text-6xl',
								lead ? ['md:max-w-[483px]', 'shrink-0'] : ['md:max-w-[1000px]'],
							)}
						>
							{heading}
						</Heading>
						{lead && (
							<div
								className={clsx(
									'md:max-w-[910px]',
									'pt-1',
									'md:pt-6',
									textAlignment === 'right'
										? 'text-right *:justify-self-end'
										: 'text-left',
								)}
							>
								{lead}
							</div>
						)}
					</div>
				</Container>
			)}
		</>
	);
};
